/* Bootstrap Overrides */
.app .main .container-fluid {
  padding: 0;
}

.app .breadcrumb {
  margin-bottom: 0;
}

.modal-dialog {
  margin: 0;
}


.form-group > label {
  font-weight: bold !important;
}

.form-control,
.btn,
.form-control:focus {
  box-shadow: none !important;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.table-responsive {
  min-height: 300px;
}

.input-group + .invalid-feedback {
  display: block;
}

/* Common Utils */
.clickable {
  cursor: pointer !important;
}

/* react-datepicker + bootstrap compatibility */
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker-wrapper + .invalid-feedback {
  display: block;
}

.react-datepicker-popper {
  z-index: 9;
}

@media (min-width: 576px) {
  .app .main .container-fluid {
    padding: 0 15px;
  }

  .app .breadcrumb {
    margin-bottom: 1rem;
  }

  .modal-dialog {
    margin: 1.75rem auto;
  }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}
